import {
  ensureEndsWithPunctuation,
  formatSingleName,
  splitSingleName,
} from "../lib/functions";
import dictionaryDataHelpers from "./dictionaryDataHelpers";

export default {
  iris(): string {
    return "";
  },

  getAuthors() {},

  oasisSummaryWriters(dictionaryDataState: any, materialMetadata: any) {
    let summaryWriters = "";

    try {
      if (dictionaryDataState) {
        const writers = dictionaryDataHelpers.getDDitem(
          dictionaryDataState,
          "oasisAuthors",
          materialMetadata.summary.summaryWriter
        );

        if (writers.length > 0) {
          let joinWith = ", ";

          writers.map((item: any, index: any, arr: any) => {
            // change last joining character
            if (index === arr.length - 2) joinWith = " & ";
            if (index === arr.length - 1) joinWith = "";
            const writer = formatSingleName(item.label);
            summaryWriters += `${writer}${joinWith}`;
            return writer;
          });
        }
      }
    } catch (error) {
      console.log("🚀 ~ oasisSummaryWriters ~ error:", error);
    }

    return summaryWriters;
  },

  oasisSummaryPublishedYear(objectMetadata: any) {
    let summaryPublishingYear: any = "";

    try {
      if (objectMetadata && objectMetadata.createdAt) {
        const date = new Date(objectMetadata.createdAt).getFullYear();
        summaryPublishingYear = date;
      }
    } catch (error) {
      console.log("🚀 ~ oasisSummaryPublishedYear ~ error:", error);
    }

    return summaryPublishingYear;
  },

  oasisSummaryTitle(materialMetadata: any) {
    let summaryTitle: any = "";

    try {
      summaryTitle = ensureEndsWithPunctuation(
        materialMetadata.summary.summaryTitle
      );
    } catch (error) {
      console.log("🚀 ~ oasisSummaryTitle ~ error:", error);
    }

    return summaryTitle;
  },

  oasisPublicationAuthors(dictionaryDataState: any, materialMetadata: any) {
    let summaryWriters = "";

    try {
      if (dictionaryDataState) {
        const writers = dictionaryDataHelpers.getDDitem(
          dictionaryDataState,
          "oasisAuthors",
          materialMetadata.publication[0].publicationAuthor
        );

        if (writers.length > 0) {
          let joinWith = ", ";

          writers.map((item: any, index: any, arr: any) => {
            // change last joining character
            if (index === arr.length - 2) joinWith = " & ";
            if (index === arr.length - 1) joinWith = "";
            const writer = splitSingleName(item.label); // !! Changed due to request
            summaryWriters += `${writer[1]}${joinWith}`;
            return writer;
          });
        }
      }
    } catch (error) {
      console.log("🚀 ~ oasisPublicationAuthors ~ error:", error);
    }

    return summaryWriters;
  },

  oasisPublicationYear(materialMetadata: any) {
    let publicationpublishingYear = "";

    try {
      if (
        materialMetadata.publication &&
        materialMetadata.publication.length > 0 &&
        materialMetadata.publication[0].publicationDate
      ) {
        publicationpublishingYear =
          materialMetadata.publication[0].publicationDate.label;
      }
    } catch (error) {
      console.log("🚀 ~ oasisPublicationYear ~ error:", error);
    }

    return publicationpublishingYear;
  },

  oasisPublicationJournalName(dictionaryDataState: any, materialMetadata: any) {
    let publicationJournalName = "";

    try {
      if (dictionaryDataState) {
        const journalName = dictionaryDataHelpers.getDDitem(
          dictionaryDataState,
          "oasisPublicationJournals",
          materialMetadata.publication[0].publicationJournalName
        );

        if (journalName && journalName.length > 0) {
          publicationJournalName = journalName[0].label;
        }
      }
    } catch (error) {
      console.log("🚀 ~ oasisPublicationJournalName ~ error:", error);
    }

    return publicationJournalName;
  },

  oasisPublicationIdentifier(materialMetadata: any) {
    let publicationIdentifier = "";

    try {
      if (materialMetadata.publication[0].publicationIdentifier) {
        publicationIdentifier =
          materialMetadata.publication[0].publicationIdentifier;
      }
    } catch (error) {
      console.log("🚀 ~ oasisPublicationIdentifier ~ error:", error);
    }

    return publicationIdentifier;
  },

  oasisPublicationTitle(materialMetadata: any) {
    let publicationTitle = "";

    try {
      if (materialMetadata.publication[0].publicationTitle) {
        publicationTitle = ensureEndsWithPunctuation(
          materialMetadata.publication[0].publicationTitle
        );
      }
    } catch (error) {
      console.log("🚀 ~ oasisPublicationTitle ~ error:", error);
    }

    return publicationTitle;
  },

  oasisSummaryFullUrl(materialId: any) {
    let oasisSummaryFullUrl = "";

    try {
      oasisSummaryFullUrl = `https://www.oasis-database.org/details/${materialId}`;
    } catch (error) {
      console.log("🚀 ~ oasisSummaryFullUrl ~ error:", error);
    }

    return oasisSummaryFullUrl;
  },

  oasis(
    materialId: string,
    materialMetadata: any,
    objectMetadata: any,
    dictionaryDataState: any
  ): any {
    //

    const summaryWriters = this.oasisSummaryWriters(
      dictionaryDataState,
      materialMetadata
    );

    // console.log("🚀 ~ summaryWriters:", summaryWriters);

    const summaryPublishingYear =
      this.oasisSummaryPublishedYear(objectMetadata);

    // console.log("🚀 ~ summaryPublishingYear:", summaryPublishingYear);

    const summaryTitle = this.oasisSummaryTitle(materialMetadata);

    // console.log("🚀 ~ summaryTitle:", summaryTitle);

    const publicationAuthors = this.oasisPublicationAuthors(
      dictionaryDataState,
      materialMetadata
    );

    // console.log("🚀 ~ publicationAuthors:", publicationAuthors);

    const publicationPublishingYear =
      this.oasisPublicationYear(materialMetadata);

    // console.log("🚀 ~ publicationPublishingYear:", publicationPublishingYear);

    const publicationJournalName = this.oasisPublicationJournalName(
      dictionaryDataState,
      materialMetadata
    );

    // console.log("🚀 ~ publicationJournalName:", publicationJournalName);

    const oasisSummaryFullUrl = this.oasisSummaryFullUrl(materialId);

    // console.log("🚀 ~ oasisSummaryFullUrl:", oasisSummaryFullUrl);

    //

    return `${summaryWriters} (${summaryPublishingYear}). ${summaryTitle} <i>OASIS Summary</i> of ${publicationAuthors} (${publicationPublishingYear}) in <i>${publicationJournalName}</i>. ${oasisSummaryFullUrl}`;
  },
};
