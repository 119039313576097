import React, { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { graphql, useStaticQuery } from "gatsby";
import H2 from "../htmlElements/h2";
import Button from "../htmlElements/button";
import DetailsLeftColumnAdminActions from "./AdminActions";
import CopyToClibboardIcon from "../htmlElements/copyToClibboardIcon";
import { DictionaryDataState } from "../../context/DictionaryDataContext";
import createHowToCite from "../../helpers/createHowToCite";

function shortenFilename(materialId: string, filename: string): string {
  // Remove the initial identifier part (e.g., "w3763712d-")
  const modifiedFilename = filename.replace(`${materialId}-`, "");

  // Split the filename into name and extension
  const extension = modifiedFilename.split(".").pop();
  const namePart = modifiedFilename.replace(`.${extension}`, "");

  // Shorten the middle part if it's too long
  const maxLength = 30; // Adjust as needed
  const shortenedName =
    namePart.length > maxLength
      ? `${namePart.slice(0, maxLength - 3)}...`
      : namePart;

  // Return the shortened filename with the extension
  return `${shortenedName}.${extension}`;
}

function DownloadFilesButtons(props: any) {
  const { materialId, materialMetadata, downloadFiles, openDownloadModal } =
    props;

  const downloadButtons: any = [];

  downloadFiles.forEach((downloadFile: any) => {
    const { downloadFilename, url } = downloadFile;
    const filenameShort = shortenFilename(materialId, downloadFilename);
    const fileExtension = downloadFilename.split(".").pop();
    const defaultInnerContent = `Download file '${filenameShort}'`;
    const [innerContent, setInnerContent] = useState(defaultInnerContent);

    const resetInnerContent = (response: any) => {
      setInnerContent(defaultInnerContent);
    };

    const downloadButton = (
      <li className="pb-5" key={downloadFilename || url}>
        <Button
          innerContent={innerContent}
          additionalClasses="download-button primaryBackgroundColor"
          color="blue"
          fullWidth
          textSize="xs"
          onClick={() => {
            if (typeof window !== "undefined") {
              if (window && window.gtag) {
                const fileDownloadEventParameters = {
                  file_extension: fileExtension,
                  file_name: downloadFilename,
                  link_classes: "",
                  link_domain: "",
                  link_id: materialId,
                  link_text: downloadFilename,
                  link_url: url,
                };

                window.gtag(
                  "event",
                  "file_download",
                  fileDownloadEventParameters
                );
              }
            }

            setInnerContent(`Start downloading '${filenameShort}'`);
            openDownloadModal(downloadFile, resetInnerContent);
          }}
        />
      </li>
    );

    downloadButtons.push(downloadButton);
  });

  if (materialMetadata && materialMetadata.research) {
    const downloadButton = (
      <li className="pb-5" key={uuidv4()}>
        <Button
          innerContent="Preview summary in a new tab"
          additionalClasses="download-button primaryBackgroundColor"
          color="blue"
          fullWidth
          textSize="xs"
          onClick={() => {
            if (window.gtag) {
              const fileDownloadEventParameters = {
                file_extension: "pdf",
                file_name: "OASIS_PDF_Summary.pdf", // would this capture the new PDF preview?
                link_classes: "",
                link_domain: "",
                link_id: materialId,
                link_text: "OASIS_PDF_Summary.pdf", // would this capture the new PDF preview?
                link_url: `/details/${materialId}/pdf/`,
              };

              window.gtag(
                "event",
                "file_download",
                fileDownloadEventParameters
              );
            }

            window.open(`/details/${materialId}/pdf/`);

            // setInnerContent(`Start downloading '${downloadFilename}'`);
            // openDownloadModal(downloadFile, resetInnerContent);
          }}
        />
      </li>
    );

    downloadButtons.push(downloadButton);
  }

  return (
    <div className="pb-5 border-b border-b-gray-2 mb-5 download-buttons">
      <ul>{downloadButtons}</ul>
    </div>
  );
}

interface DetailsLeftColumnContentsProps {
  materialId: string;
  filesMetadata: object;
  doiReference: string;
  openDownloadModal: unknown;
  userRightsAndSettings: object;
  projectName: string;
  materialMetadata: any;
  objectMetadata: any;
}

export default function DetailsLeftColumnContent(
  props: DetailsLeftColumnContentsProps
) {
  const {
    materialId,
    filesMetadata,
    doiReference,
    openDownloadModal,
    userRightsAndSettings,
    projectName,
    materialMetadata,
    objectMetadata,
  } = props;

  const dictionaryDataState = useContext(DictionaryDataState) || [];

  const [apaReference, setApaReference] = useState<any>(doiReference);

  const downloadButtons = (
    <DownloadFilesButtons
      materialId={materialId}
      downloadFiles={filesMetadata}
      openDownloadModal={openDownloadModal}
      materialMetadata={materialMetadata}
    />
  );

  const DownloadHeaderText =
    projectName === "IRIS" ? "Download materials" : "Download summary";

  useEffect(() => {
    const fetchApaReference = async () => {
      try {
        if (projectName === "OASIS") {
          if (
            materialMetadata?.publication &&
            materialMetadata.publication.length > 0 &&
            dictionaryDataState
          ) {
            const howToCite = createHowToCite.oasis(
              materialId,
              materialMetadata,
              objectMetadata,
              dictionaryDataState
            );

            setApaReference(howToCite);
          }
        }
      } catch (error) {
        console.error("🚀 ~ fetchApaReference ~ error:", error);
      }
    };

    fetchApaReference();
  }, [materialMetadata, dictionaryDataState]);

  const { irisText, oasisText } = useStaticQuery(graphql`
    query {
      irisText: markdownRemark(
        frontmatter: { pageId: { eq: "IRIS-details-page" } }
      ) {
        frontmatter {
          licenceInfoTitle
          licenceInfoText
        }
      }

      oasisText: markdownRemark(
        frontmatter: { pageId: { eq: "OASIS-details-page" } }
      ) {
        frontmatter {
          licenceInfoTitle
          licenceInfoText
        }
      }
    }
  `);

  let licenceInfoTitle = "";
  let licenceInfoText = "";

  if (irisText && projectName === "IRIS") {
    licenceInfoTitle = irisText.frontmatter.licenceInfoTitle;
    licenceInfoText = irisText.frontmatter.licenceInfoText;
  }

  if (oasisText && projectName === "OASIS") {
    licenceInfoTitle = oasisText.frontmatter.licenceInfoTitle;
    licenceInfoText = oasisText.frontmatter.licenceInfoText;
  }

  return (
    <>
      {/* <div className="grid grid-cols-2 gap-4 pb-5">
        <div className="col-span-1 p-5 text-center h-[200px] bg-iris-blue-light">
          Image
        </div>

        <div className="col-span-1 p-5 text-center h-[200px] bg-iris-blue-light">
          Image
        </div>

        <div className="col-span-1 p-5 text-center h-[200px] bg-iris-blue-light">
          Image
        </div>

        <div className="col-span-1 p-5 text-center h-[200px] bg-iris-blue-light">
          Image
        </div>
      </div> */}
      {/* <div className="pb-5">
        <Button
          innerContent="Download all material(s)"
          color="blue"
          fullWidth={true}
          additionalClasses="mb-5"
          onClick={() => openDownloadModal("all")}
        />
      </div>

      <span className="text-center block pb-5">or</span> */}

      <H2 innerContent={DownloadHeaderText} additionalClasses="pb-5" />

      {downloadButtons}

      <div className="pb-5 border-b border-b-gray-2 mb-5">
        <H2 innerContent={licenceInfoTitle} />
        <p className="text-sm">
          <a href="http://creativecommons.org/licenses/by-nc-sa/3.0/">
            <img
              src="/images/by-nc-sa.png"
              width="68"
              height="25"
              alt={licenceInfoText}
              title={licenceInfoText}
              style={{ float: `left`, padding: `6px 10px 6px 0px` }}
            />
          </a>
          {licenceInfoText}
        </p>
      </div>

      {apaReference ? (
        <div className="pb-5 mb-5 relative howToCite">
          <H2
            innerContent={
              projectName === "IRIS"
                ? `How to cite this material`
                : `How to cite this summary`
            }
            additionalClasses="mb-2 key"
          />
          <div className="text-sm">
            <div
              className="markdownText"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: apaReference,
              }}
            />
          </div>
          <div className="absolute right-2 top-1">
            <CopyToClibboardIcon text={apaReference} />
          </div>
        </div>
      ) : null}

      {/* <div className="grid grid-cols-2 gap-4 pb-5 border-b border-b-gray-2 mb-5">
        <div className="">
          <Button
            innerContent="Leave feedback"
            color="blue"
            fullWidth={true}
            onClick={() => props.openFeedbackModal()}
          />
        </div>
        <div className="">
          <Button
            innerContent="Email to friend"
            color="blue"
            fullWidth={true}
            additionalClasses="inline-block"
            onClick={() => props.openEmailModal()}
          />
        </div>
      </div> */}

      <DetailsLeftColumnAdminActions
        materialId={materialId}
        userRightsAndSettings={userRightsAndSettings}
        projectName={projectName}
      />
    </>
  );
}
